










































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  getCompanyList,
  companyList,
  scheduleListByCompanyId,
  deleteCompanySchedule,
  submitSources,
} from "@/serve/medicalAppointment/plan";
import { Calendar } from "element-ui";
import { Card } from "element-ui";
@Component({
  name: "companyManage",
  components: { Calendar, Card },
})
export default class MedicalReport extends Vue {
  //批量设置号源
  public selectedBatch: any = []; // 批量处理的数组，存放选中的日期项索引
  public batchInputValue: any = 0; // 批量设置的输入框值

  //批量选中状态
  public selectAllData: any = false; 

  //单选选择事件
  public handleRadioChange(index: any, item: any) {
    console.log("index", index);
    console.log("item", item);
    // 根据选中状态添加或移除索引
    const idx = this.selectedBatch.indexOf(index);
    if (idx !== -1) {
      this.selectAllData = false;
      this.selectedBatch.splice(idx, 1);
    } else {
      this.selectedBatch.push(index);
    }
    // 更新批量设置的输入框值到选中的项中
    this.updateBatchInputToSelected();
  }

  //全选事件
  public selectAll(e: any){
    console.log("全选事件e",e)
    if(this.selectAllData){
      for(let i = 0; i < this.selectedDates.length; i++){
        console.log("全选事件i",i)
        // this.selectedBatch.push(i)
        this.selectedBatch.push(i);
        this.selectedDates[i].isSelected = true;
      }
      // this.selectedBatch = [...Array(this.selectedDates.length).keys()];
      // console.log("全选事件this.selectedBatch",this.selectedBatch)
    }else{
      this.selectedBatch = [];
      this.selectedDates.forEach((item: any, index: any) => {
        item.isSelected = false;
      });
    }
  }

  public updateBatchInputToSelected() {
    const inputValue = parseInt(this.batchInputValue, 10);
    if (!isNaN(inputValue)) {
      this.selectedDates.forEach((item: any, index: any) => {
        console.log("updateBatchInputToSelected", index);
        console.log("this.selectedBatch.includes(index)",this.selectedBatch.includes(index))
        if (this.selectedBatch.includes(index)) {
          item.companyNum = inputValue;
        }
      });
    }
  }

  // 输入框值变化时触发
  onBatchInput(e: any) {
    const inputValue = parseInt(e, 10) || 0;
    if (e <= 0) {
      this.batchInputValue = 0;
    } else {
      this.batchInputValue = inputValue;
    }

    this.updateBatchInputToSelected();
  }

  public selectedDates: any = []; // 存储选中的日期
  public sources: any = []; // 号源数据
  public handleDateSelect(date: any) {
    // 这里可以处理整个日历的输入事件，比如清空选择等
    console.log("handleDateSelect", date);
  }
  public toggleDateSelection(date: any) {
    console.log("触发toggleDateSelection");
    const formattedDate: any = this.formatDate(date);
    const source = this.sources.find((s: any) => s.date === formattedDate);
    const selectedData = {
      date: formattedDate,
      daySurPlus: source ? source.daySurPlus : 0,
      companyNum: source ? source.companyNum : 0,
      scheduleId: source ? source.scheduleId : 0,
    };
    if (!this.isDateAvailable(date)) {
      this.$message("当日没有可选号源");
      return;
    }
    console.log("formattedDate", formattedDate);
    const index = this.selectedDates.findIndex(
      (d: any) => d.date === formattedDate
    );
    if (index !== -1) {
      this.selectedDates.splice(index, 1);
    } else {
      console.log("进添加", formattedDate);
      this.selectedDates.push(selectedData);
    }

    console.log("选到的", this.selectedDates);
  }
  public isSelected(date: any) {
    const formattedDate = this.formatDate(date);
    return this.selectedDates.some((item: any) => item.date === formattedDate);
  }

  public getSourceNum(date: any) {
    const formattedDate = this.formatDate(date);
    const source = this.sources.find((s: any) => s.date === formattedDate);
    return source ? source.daySurPlus : 0;
  }

  public companyNum(date: any) {
    const formattedDate = this.formatDate(date);
    const source = this.sources.find((s: any) => s.date === formattedDate);
    return source ? source.companyNum : 0;
  }
  public isDateAvailable(date: any) {
    // 逻辑判断该日期是否有号源
    const formattedDate = this.formatDate(date);
    return this.sources.some((source: any) => source.date === formattedDate);
  }
  public formatDate(date: any) {
    return date.toISOString().split("T")[0];
  }
  public form = {
    //搜索单位名称
    name: "",
    //当前页面
    current: 1,
    //每页数量
    size: 10,
  };
  //分页器参数
  public pagination = {
    //每页数量
    siez: 0,
    //总页
    total: 0,
    //当前页
    current: 0,
  };
  //日期选择器
  public value = new Date();
  //是否展示号源编辑框
  public dialogTime = false;
  public tableData = [
    {
      //单位id
      id: 1,
      //单位名称
      name: "",
    },
  ];
  public created() {
    //获取列表
    this.getReportList();
    //获取单位
    this.fetchCompany();
  }
  //单位数组
  public companyList = [
    {
      id: "",
      name: "",
    },
  ];

  //操作号源选择日期时
  public selectChange(date: any) {
    console.log("选择的日期", date);
  }
  //获取单位选择框
  public fetchCompany() {
    getCompanyList({
      tenantId: sessionStorage.getItem("tenant_id"),
      size: 999,
    }).then((res: any) => {
      this.companyList = res.records;
    });
  }
  // 获取数据接口
  public async getReportList() {
    console.log("获取表格数据");
    companyList(this.form).then((res: any) => {
      console.log("表格数据", res);
      this.tableData = res.records;
      this.pagination.siez = res.size;
      this.pagination.total = res.total;
      this.pagination.current = res.current;
    });
  }
  // 筛选按钮
  public onSubmit() {
    // 点击筛选后 初始化值
    this.form.size = 10;
    this.form.current = 1;
    this.getReportList();
  }

  //reset重置搜索框
  public reset() {
    (this.form.current = 1), (this.form.size = 10), (this.form.name = "");
    this.getReportList();
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getReportList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getReportList();
  }
  //提交数据
  public submitData() {
    console.log("提交数据号源", this.selectedDates);
    const params: any = {
      companyId: this.companyId,
      timeList: [],
    };

    for (let i = 0; i < this.selectedDates.length; i++) {
      const data: any = {
        scheduleId: this.selectedDates[i].scheduleId,
        number: parseInt(this.selectedDates[i].companyNum),
      };
      params.timeList.push(data);
    }
    submitSources(params).then((res: any) => {
      if (res.code === 200) {
        this.$message({
          message: "提交成功",
          type: "success",
        });
        scheduleListByCompanyId(this.companyId).then((obj: any) => {
          console.log("res", obj);
          this.sources = obj.data;
        });
        this.batchInputValue = 0;
        this.selectedDates = [];
        this.selectAllData=false
      }
    });
    console.log("parmars", params);
  }

  //重置方法
  public remove() {
    (this.companyId = 0), (this.companyName = ""), (this.selectedDates = []);
    this.selectAllData=false
    this.getReportList();
  }

  //删除用的id
  public companyId = 0;
  public companyName = "";

  public deleteCompanySchedule(date: any) {
    const formattedDate: any = this.formatDate(date);
    const source = this.sources.find((s: any) => s.date === formattedDate);

    (this as any).$messageBox
      .confirm(`您确定要删除号源吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        console.log("点击了删除");
        const params = {
          companyId: this.companyId,
          scheduleId: source.scheduleId,
        };
        deleteCompanySchedule(params).then((res: any) => {
          if (res.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            scheduleListByCompanyId(this.companyId).then((obj: any) => {
              console.log("res", obj);
              this.sources = obj.data;
            });
            this.selectedDates = [];
            // this.dialogTime=false
            // this.getReportList();
          }
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }

  //获取号源
  public changeSoure(data: any) {
    console.log("data", data);
    this.dialogTime = true;
    this.companyId = data.companyId;
    this.companyName = data.companyName;
    scheduleListByCompanyId(data.companyId).then((res: any) => {
      console.log("res", res);
      this.sources = res.data;
    });
  }
}
